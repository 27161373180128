<script lang="ts">
  import type { SectionHero } from '$lib/contentful/models/section-hero';
  import Text from '$lib/components/text/text.svelte';
  import Box from '../../layout/grid/box.svelte';
  import Grid from '../../layout/grid/grid.svelte';
  import Button from '$lib/components/button/button.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import RichText from '../rich-text/rich-text.svelte';

  export let content: SectionHero;

  $: ({
    eyebrow,
    heading,
    body,
    image,
    callsToAction = [],
    anchor,
    background,
  } = content);
</script>

<Grid as="section" layout="columns" {background} id={anchor}>
  <Box as="div" class="flex max-lg:flex-col lg:flex-row justify-between gap-16">
    <div class="content">
      {#if eyebrow}
        <Text {background} as="h2" variant="eyebrow-16">
          {eyebrow}
        </Text>
      {/if}
      {#if heading}
        <Text {background} as="h3" variant="display-68">
          {heading}
        </Text>
      {/if}
      {#if body}
        <RichText theme="dark" text={body} />
      {:else}
        <slot />
      {/if}
      <div class="flex gap-4">
        {#each callsToAction as { text, ...rest }}
          <Button target="_blank" {...rest}>{text}</Button>
        {/each}
      </div>
    </div>
    {#if image}
      <ContentfulImage
        class="max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl object-contain"
        src={image.url}
        alt={image.title}
      />
    {/if}
  </Box>
</Grid>

<style lang="postcss">
  .content {
    @apply flex flex-col gap-8 lg:max-w-[60%];
  }
</style>
